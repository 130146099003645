import React from "react";
import menus from "../data/menuData"; // Import the menu data
import "./Menu.css";

const Menu = () => {
  return (
    <div>
      <div className="menu-title">12月桌菜菜單</div> {/* 新增標題 */}
      <div className="menu-container">
        {menus.map((menu, index) => (
          <div key={index} className="menu-box">
            <h2 className="gradient-text">{menu.name}</h2>
            <ul>
              {menu.items.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="menu-info">
        <p>●桌菜需提前訂位</p>
        <p>●食材可更換等值品項</p>
        <p>●免收開瓶費</p>
        <p>●均另收服務費10%</p>
        <p>●如因季節缺貨將替換等值食材</p>
      </div>
    </div>
  );
};

export default Menu;
