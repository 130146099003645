const menus = [
  {
    name: "$12,800",
    items: [
      "晶櫻燴龍蝦雙拼",
      "精選刺身盛合",
      "皇家脆皮炸豬腳",
      "蒜蓉海大蝦",
      "蔥香清蒸海上鮮",
      "碧綠蹄筋燴烏參",
      "瑤柱枸杞長年菜",
      "上海蟹粉菜飯",
      "極地帝王蟹米粉鍋",
      "精緻招牌甜湯",
      "四季時令水果盤",
    ],
  },
  {
    name: "$16,800",
    items: [
      "晶櫻燴龍蝦雙拼",
      "極上刺身盛合",
      "晶櫻燴佛跳牆",
      "香烤極地鱈場蟹",
      "蔥香清蒸海上鮮",
      "宮廷鮑魚燴烏參",
      "蟹黃如意長年菜",
      "XO醬蝦仁炒飯",
      "巴西蘑菇燉雞湯",
      "精緻招牌甜湯",
      "四季時令水果盤",
    ],
  },
  {
    name: "$19,800",
    items: [
      "富貴澳龍四喜拼",
      "極上刺身盛合",
      "御品魚翅佛跳牆",
      "香烤極地鱈場蟹",
      "蔥香玉露海上鮮",
      "宮廷鮑魚燴烏參",
      "XO醬蘆筍蝦仁鮮代",
      "松露鮭魚卵炒飯",
      "砂鍋火烔雞濃湯",
      "精緻招牌甜湯",
      "四季時令水果盤",
    ],
  },
];

export default menus;
